$font-heading: Lato, Verdana, Helvetica, sans-serif;
$font-body: Lato, Verdana, Helvetica, sans-serif;
$font-size: 14px;
$primary-color: #ee5252;
$text-color: #383838;

@import '../../../../node_modules/@lib/styles/gn.styles.scss';
@import '../../../../node_modules/@lib/styles/core/index.scss';
@import '../../../../node_modules/@lib/styles/restaurant-icons/restaurant-icons-1';
@import 'type';

.introjs-helperLayer {
  opacity: 0.4;
}

.introjs-tooltiptext {
  line-height: 1.4;
  font-size: 15px;
  padding: 6px 6px;
}

.introjs-tooltipbuttons {
  text-align: center !important;
}

.introjs-button {
  font-size: 12px !important;
}

.introjs-tooltip {
  min-width: 260px !important;
  max-width: 320px !important;
}

.introjs-helperNumberLayer {
  background: #fb2f2f !important;
}

body {
  background: darken(white, 5%);
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

#scroll-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.simple-table {
  display: block;
  overflow: auto;
  width: 100%;
  tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }
  tr:nth-child(2n) {
    background-color: #f5f5f5;
  }
  th,
  td {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
    line-height: 1.4;
  }
  th {
    font-weight: 600;
  }
}
